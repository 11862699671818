import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import "./style.scss";
import {Avatar} from '../../lib/CometChat';
import { CometChat } from '@cometchat-pro/chat';
import { COMETCHAT_CONSTANTS } from '../../consts';
import axios from 'axios';


class KitchenSinkApp extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    
  }

  componentDidMount() {
    
    var id = this.props.match.params.id
    console.log(id)

    if(id!==undefined){
      const loginData = new FormData();
      loginData.append('md5id',id);
      
      const headers = {
        'Content-Type': 'multipart/form-data',
        'Auth-Key': "24ad1dc1-c5e2-4bbc-a261-9de40fa7d7c7",
        'Client-Service':'frontend-client',
        'origin':'*'
      }
      axios.post('https://mindme-cors.herokuapp.com/'+COMETCHAT_CONSTANTS.VERIFY_URL,loginData,{
        headers:headers
      }).then((response)=>{
        console.log(response)
        if(response.status == 200){
          var name = response.data.data[0].first_name + " "+ response.data.data[0].second_name
          var user = new CometChat.User(id)
          user.setName(name)
          user.setUid(id)
          if(response.data.data[0].image_path !== ""){
            var avatar_path = COMETCHAT_CONSTANTS.IMAGE_BASE_URL+response.data.data[0].image_path 
            user.setAvatar(avatar_path)
          }
        
          CometChat.createUser(user,COMETCHAT_CONSTANTS.API_KEY).then((user) => {
            console.log('user',user)
            this.setState({'userToLogin':user},()=>{
              var toId = this.props.match.params.toId
              if(toId!==undefined){
                this.checkToId()
              }else{
                this.login(id)
              }
              
            })
          }).catch((error)=>{
            console.log('error',error)
            if(error.code==="ERR_BAD_REQUEST" && error.details.uid[0]==="The uid has already been taken."){
              var toId = this.props.match.params.toId
              if(toId!==undefined){
                this.checkToId()
              }else{
                this.login(id)
              }
            }
            
          })
        }else{
          window.top.location.href = COMETCHAT_CONSTANTS.REDIRECT_URL;
        }
        
      })
    }
    
    return
  
    this.login(this.props.match.params.id)
    CometChat.getLoggedinUser().then(user => {
      if (user) {
        // window.location.href = '/';
        this.uid = user.getUid();
      } else {

        this.showUi = true;
        this.showloader = false;
      }
    }, error => {
      console.log( error);
      this.showUi = true;
      this.showloader = false;
    });

  }
  
  checkToId = () => {
    var toId = this.props.match.params.toId
    if(toId!==undefined){
      const loginData = new FormData();
      loginData.append('md5id',toId);
      
      const headers = {
        'Content-Type': 'multipart/form-data',
        'Auth-Key': "24ad1dc1-c5e2-4bbc-a261-9de40fa7d7c7",
        'Client-Service':'frontend-client',
        'origin':'*'
      }
      axios.post('https://mindme-cors.herokuapp.com/'+COMETCHAT_CONSTANTS.VERIFY_URL,loginData,{
        headers:headers
      }).then((response)=>{
        console.log(response)
        if(response.status == 200){
          console.log('this to id is valid!')
          var name = response.data.data[0].first_name + " "+ response.data.data[0].second_name
          var user = new CometChat.User(toId)
          user.setName(name)
          user.setUid(toId)
          if(response.data.data[0].image_path !== ""){
            var avatar_path = COMETCHAT_CONSTANTS.IMAGE_BASE_URL+response.data.data[0].image_path 
            user.setAvatar(avatar_path)
          }
          
          CometChat.createUser(user,COMETCHAT_CONSTANTS.API_KEY).then((user) => {
            console.log('user',user)
            //now both users are created, start a conversation between them
            CometChat.login(this.props.match.params.id, COMETCHAT_CONSTANTS.API_KEY).then((user) => {
              var conversationsRequest = new CometChat.ConversationsRequestBuilder()
                .setLimit(50)
                .build();
              var conversationExists = 0
              conversationsRequest.fetchNext().then(
                conversationList => {
                  
                  for(let i=0;i<conversationList.length;i++){
                    console.log("Conversations list received:", conversationList[i].conversationWith.uid);
                    console.log(toId.toLowerCase())
                    if(conversationList[i].conversationWith.uid === toId.toLowerCase()){
                      conversationExists =1
                    }
                  }
                  if(conversationExists === 0){
                    var textMessage = CometChat.TextMessage(
                                    toId,
                                    COMETCHAT_CONSTANTS.PLACEHOLDER_MESSAGE,
                                    CometChat.RECEIVER_TYPE.USER
    
                                    )
                    CometChat.sendMessage(textMessage).then((baseMessage) => {
                      window.location.href = '/conversation-screen/'+toId;
                    })
                  }else{
                    window.location.href = '/conversation-screen'+toId;
                  }
                },
                error => {
                  console.log("Conversations list fetching failed with error:", error);
                }
              );
              
              
            }, error => {
              console.log('CometChatLogin Failed', error);
              this.showloader = false;
            });
            
          }).catch((error)=>{
            console.log('error',error)
            if(error.code==="ERR_BAD_REQUEST" && error.details.uid[0]==="The uid has already been taken."){
              CometChat.login(this.props.match.params.id, COMETCHAT_CONSTANTS.API_KEY).then((user) => {
                var conversationsRequest = new CometChat.ConversationsRequestBuilder()
                .setLimit(50)
                .build();
              var conversationExists = 0
              conversationsRequest.fetchNext().then(
                conversationList => {
                  
                  for(let i=0;i<conversationList.length;i++){
                    console.log('user exists')
                    console.log(conversationList[i].conversationWith.uid)
                    console.log(toId.toLowerCase())
                    if(conversationList[i].conversationWith.uid === toId.toLowerCase()){
                      console.log('they are same')
                      conversationExists = 1
                    }
                  }
                  if(conversationExists === 0){
                    var textMessage = CometChat.TextMessage(
                                    toId,
                                    COMETCHAT_CONSTANTS.PLACEHOLDER_MESSAGE,
                                    CometChat.RECEIVER_TYPE.USER
    
                                    )
                    CometChat.sendMessage(textMessage).then((baseMessage) => {
                      window.location.href = '/conversation-screen/'+toId;
                    })
                  }else{
                    window.location.href = '/conversation-screen/'+toId;
                  }
                },
                error => {
                  console.log("Conversations list fetching failed with error:", error);
                }
              );
              
              }, error => {
                console.log('CometChatLogin Failed', error);
                this.showloader = false;
              });
            }
            
          })
        }else{
          window.top.location.href = COMETCHAT_CONSTANTS.REDIRECT_URL;
        }
      })
    }
  }
  
  login = (uid) => {
    if(!uid){
      uid=this.myRef.current.value
    }
    this.showloader = true;
    if (uid) {
      this.uid = uid;
    }
    
    var user = new CometChat.User(uid)
    user.setName(uid)
    user.setUid(uid)
    CometChat.createUser(user,COMETCHAT_CONSTANTS.API_KEY).then((user) => {
      console.log('user',user)
      CometChat.login(this.uid, COMETCHAT_CONSTANTS.API_KEY).then((user) => {
        window.location.href = '/conversation-screen';
      }, error => {
        console.log('CometChatLogin Failed', error);
        this.showloader = false;
      });
    }).catch((error)=>{
      console.log('error',error)
      if(error.code==="ERR_BAD_REQUEST" && error.details.uid[0]==="The uid has already been taken."){
        CometChat.login(this.uid, COMETCHAT_CONSTANTS.API_KEY).then((user) => {
          window.location.href = '/conversation-screen';
        }, error => {
          console.log('CometChatLogin Failed', error);
          this.showloader = false;
        });
      }
      
    })
    


  }
  
  render() {
    return (
      <div className="light"  >
        {/* <div style={{ "position": "fixed", "width": "100%", "height": "100%" }} > */}
          {/* <loader>

          </loader> */}
        {/* </div> */}
        <div style={{ "paddingTop": "50px", "textAlign": "center", "display": "flex" }} >
          <div style={{ "margin": "auto" }}>
            <div style={{ "width": "150px", "margin": "auto" }}>
              {/* <img style={{ "maxWidth": "100%", "maxHeight": "100%" }}
                src="https://www.cometchat.com/wp-content/uploads/2018/03/Logo-C-White.png" alt="" /> */}
            </div>

            <p style={{ "margin": "auto", "fontSize": "42px", "color": "#2da7ff", " fontWeight": "500", "lineHeight": "54px" }}>Loading Chat...
        </p>

            {/* <p style={{ "margin": "auto", "padding": "10px" }}>
              Login with one of our sample users
        </p> */}
            <div style={{ "display": "flex", "width": "100%", "flexWrap": "wrap", "margin": "auto" }}>
              {/* <div className="userSelector">
                <Avatar src={{avatar:'https://data-us.cometchat.io/assets/images/avatars/ironman.png',name:'iron man',uid:'superhero1'}}
                  style={{ "margin": "auto", "width": "10%", "marginTop": " 13px" }}></Avatar>
                <p style={{ "margin": "auto" }}><a onClick={()=>this.login('superhero1')} href="#"> superhero1</a></p>
              </div>
              <div className="userSelector">
                <Avatar src={{avatar:'https://data-us.cometchat.io/assets/images/avatars/captainamerica.png',name:'captain america ',uid:'superhero1'}}
                  style={{ "margin": "auto", "width": "10%", "marginTop": " 13px" }}></Avatar>
                <p style={{ "margin": "auto" }}><a onClick={()=>this.login('superhero2')} href="/#"> superhero2</a></p>
              </div>
              <div className="userSelector">
                <Avatar src={{avatar:'https://data-us.cometchat.io/assets/images/avatars/spiderman.png',name:'spiderman',uid:'superhero1'}}
                  style={{ "margin": "auto", "width": "10%", "marginTop": " 13px" }}></Avatar>
                <p style={{ "margin": "auto" }}><a onClick={()=>this.login('superhero3')} href="/#"> superhero3</a></p>
              </div>
              <div className="userSelector">
                <Avatar src={{avatar:'https://data-us.cometchat.io/assets/images/avatars/wolverine.png',name:'wolverine ',uid:'superhero1'}}
                  style={{ "margin": "auto", "width": "10%", "marginTop": " 13px" }}></Avatar>
                <p style={{ "margin": "auto" }}><a onClick={()=>this.login('superhero4')} href="/#"> superhero4</a></p>
              </div>
              <div className="userSelector">
                <Avatar src={{avatar:'https://data-us.cometchat.io/assets/images/avatars/cyclops.png',name:'cyclops',uid:'superhero1'}} 
                  style={{ "margin": "auto", "width": "10%", "marginTop": " 13px" }}></Avatar>
                <p style={{ "margin": "auto" }}><a onClick={()=>this.login('superhero5')}  href="/#"> superhero5</a>
                </p>
              </div> */}
            </div>
            {/* <p style={{"margin": "auto", "padding": "10px"}}> Login continue with UID</p>
            <input ref={this.myRef} style={{"margin": "auto", "padding": "10px"}} type="text" placeholder="Enter your UID here"/>

              <div className="loginButton" onClick={()=>this.login()}> Login</div> */}
    </div>

          </div>

        </div>
        );
      }
    }
    
    export default KitchenSinkApp;
