import React from "react";
import "./style.scss";
import ChatHeader from "../ChatHeader";
import MessageComposer from "../MessageComposer";
import ChatWindow from "../ChatWindow";
import UserProfile from "../UserProfile"
import { CometChatManager } from "../CallScreen/controller";
import { CometChat } from "@cometchat-pro/chat";
import CallScreen from "../CallScreen";

class CometChatMessageScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chatList: [],
      onItemClick: null,
      messages: undefined,
      showOutgoingCall:false,
      userClicked:'no',
      actionGenerated: () => {
        return null;
      }
    }

  }

  static getDerivedStateFromProps(props, state) {
    return props;

  }
  componentDidMount(){
    this.cometChatManager = new CometChatManager();

    let listenerID = "1234567890";
    
    CometChat.addCallListener(
      listenerID,
      new CometChat.CallListener({
        onIncomingCallReceived: (call) => {
          if (!this.state.callIProgress) {
            // this.audio.play()
            this.setState({
              // messages: [call],
              showOutgoingCall:false,
              outgoingCall:undefined,
              userClicked:'no'
            });
            this.state.actionGenerated("callEnded", {call:call});
          } else {
            //TODO reject the call
          }
          
        },
        onOutgoingCallAccepted: (call) => {
          console.log('outgoing call accepted','cometchatmessage')
          console.log(call);
          this.audio.pause()
          this.setState({
            // messages: [call],
            showOutgoingCall:false,
            outgoingCall:undefined,
            userClicked:'no'
          });
          this.state.actionGenerated("callEnded", {call:call});
        },
        onOutgoingCallRejected: (call) => {
          console.log("here we ate ", call)
          this.audio.pause()
          this.setState({
            // messages: [call],
            showOutgoingCall:false,
            outgoingCall:undefined,
            userClicked:'no'
          });
          this.state.actionGenerated("callEnded", {call:call});
        },
        onIncomingCallCancelled: (call) => {
          console.log('incoming call cancelled')
          this.audio.pause()
          this.setState({
            // messages: [call],
            showOutgoingCall:false,
            outgoingCall:undefined,
            userClicked:'no'
          });
          
          this.state.actionGenerated("callEnded", {call:call});
        }
      })
    );

  }
  render() {
    return (
      <div className="cp-chatview-container-wrapper" >
        <div className="cp-chatview-container" >
          <ChatHeader item={this.state.item} type={this.state.type} onActionGenerated={(action, payload) => {
            this.state.actionGenerated(action, payload);
            switch (action) {
              case 'audioCallInitiated':
                console.log("AUDIO calll started", payload);
               
                this.setState({ messages: [payload.call], showOutgoingCall:true, outgoingCall: payload.call, userClicked:'yes'});
                break;
              case 'videoCallInitiated':
                console.log("VIDEOP calll started", payload);
                this.setState({ messages: [payload.call], showOutgoingCall:true, outgoingCall: payload.call, userClicked:'yes' });

                break;
              case 'toggelProfile':
                this.setState({
                  ...payload
                })
                break;
            }

          }}></ChatHeader>
          <div className="cp-chatwindow-conatiner" >
            <ChatWindow showOutgoingCall={this.state.showOutgoingCall} outgoingCall={this.state.outgoingCall} userClicked={this.state.userClicked} inputMessageList={(this.state.messages !== undefined ? this.state.messages : "")} item={this.state.item} type={this.state.type}></ChatWindow>
          </div>

          <MessageComposer onMessageSent={(message) => {
            this.setState({ messages: [message] });
          }} item={this.state.item} type={this.state.type}></MessageComposer>
        </div>
        {this.state.toggleUserProfile ? <div className="cp-profile-container">
          <UserProfile item={this.state.item} type={this.state.type} ></UserProfile>

        </div> : ""}
        <CallScreen showOutgoingCall={this.state.showOutgoingCall} outgoingCall={this.state.outgoingCall} userClicked={this.state.userClicked} actionGenerated={(action,payload) => {
          if(action==="callCancelled"){
            this.setState({messages:[payload.call], showOutgoingCall:false, outgoingCall: undefined, userClicked:'no' })
          }
          if(action==="callEnded"){
            console.log('action generated callended')
            this.setState({showOutgoingCall:false,outgoingCall:undefined,userClicked:'no'})
          }
          if(action==="userLeft"){
            console.log('action generated callended')
            this.setState({showOutgoingCall:false,outgoingCall:undefined,userClicked:'no'})
          }
          
        }} />
      </div>

    );
  }
}



export default CometChatMessageScreen;
export const cometChatMessageScreen = CometChatMessageScreen;

CometChatMessageScreen.defaultProps = {
  CometChatMessageScreen: {}
};
