export const COMETCHAT_CONSTANTS = {
    APP_ID: '1884852d82eb769',
    REGION: 'eu',
    API_KEY: 'f4ab9da931d6f755e10fe94a25c98e517d1f4a1c',
    IMAGE_BASE_URL: 'https://getababysitter.co.nz/',
    VERIFY_URL: 'https://getababysitter.co.nz/appapi/Auth/checkUserForChat',
    PLACEHOLDER_MESSAGE: 'STARTFLAG',
    SOUND_URL:'https://getababysitter.co.nz/sound/classic_cell_ring.mp3',
    REDIRECT_URL:'https://www.getababysitter.co.nz/pricing.php'
}

