import React from "react";
import "./style.scss";
import CometChatMessageScreen from "../CometChatMessageScreen"
import CometChatConversationList from "../CometChatConversationList";

import { Modal } from 'antd'


class CometChatConversationListScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      darktheme: false,
      toggleScreen: false,
      show: false,
      visible:true
    }

  }
  static getDerivedStateFromProps(props, state) {
    return props;
  }

  changeTheme = (e) => {
    this.setState({
      darktheme: !this.state.darktheme
    })
  }
  handleOk = () => {

  }

  handleCancel = () =>{
    this.setState({visible:false})
  }
  componentDidMount() {
    
    console.log(window.innerWidth);
    var id = this.props.match.params.id
    console.log(window.innerWidth)
   
    if(window.innerWidth <= 450) {
      this.setState({
        show: true
      })
    } else{
      this.setState({
        show: false
      })
    }
  }

  toggleScreen = () => {
    
    this.setState({ 
      toggleScreen: true
    })

    // setTimeout(() => {
    //   console.log(this.state.toggleScreen);
    // },2000);
  }

  render() {
    return (
      <div className="container-fluid main_container_of_screen">
        <div className={"row cometchat-container " + (this.state.darktheme ? "dark" : "light")}>
          <div className="col-xs-12 col-sm-12 col-md-5 col-lg-3 col-xl-3 cp-lists-container"
            style={{ display: (this.state.show === true) ? ((this.state.toggleScreen === true) ? "none" : "block") : "block" }}
          >
            <div className="cp-lists">
            {
              this.state.show === true ? 
                  <CometChatConversationList onItemClick={(item, type) => {
                    this.setState({ item, type })
                      this.toggleScreen() 
                  }} selectedChat={(this.props.match.params.id!==undefined) ? this.props.match.params.id : "0"}></CometChatConversationList>
              : <CometChatConversationList onItemClick={(item, type) => {
                
                this.setState({ item, type })
              }} selectedChat={(this.props.match.params.id!==undefined) ? this.props.match.params.id : "0"}></CometChatConversationList>
            } 
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-7 col-lg-9 col-xl-9 cp-chat-container"
            style={{ display: (this.state.show === true) ? ((this.state.toggleScreen === true) ? "block" : "none") : "block" }}
          >
            <div className="headerToBack">
              <button className="backbtn" onClick={() => {
                this.setState({ toggleScreen: false })
              }}>Back</button>
            </div>
            {
              this.state.item ? <CometChatMessageScreen {...this.state}></CometChatMessageScreen> : <h1 className="cp-center-text">Select a chat to start messaging</h1>
            }
            {/* <label className="switch">
              <input type="checkbox" onChange={this.changeTheme} />
              <span className="slider round"></span>
            </label> */}
          </div>
          
        </div>
      </div>
    );
  }
}



export default CometChatConversationListScreen;
export const cometChatConversationListScreen=CometChatConversationListScreen;

CometChatConversationListScreen.defaultProps = {
  launch: {}
};
