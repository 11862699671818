import React from "react";
import "./style.scss";
import SenderMessageBubble from "../SenderMessageBubble";
import ReceiverMessageBubble from "../ReceiverMessageBubble"
import SenderImageBubble from "../SenderImageBubble"
import ReceiverImageBubble from "../ReceiverImageBubble"
import SenderFileBubble from "../SenderFileBubble"
import ReceiverFileBubble from "../ReceiverFileBubble"
import SenderAudioBubble from "../SenderAudioBubble"
import ReceiverAudioBubble from "../ReceiverAudioBubble"
import SenderVideoBubble from "../SenderVideoBubble"
import ReceiverVideoBubble from "../ReceiverVideoBubble"
import { CometChatManager } from "./controller";
import { CometChat } from "@cometchat-pro/chat";
import CallMessage from "../CallMessage";
import callBlue from "./resources/call-blue-icon.svg";
import Avatar from "../Avatar";
import { COMETCHAT_CONSTANTS } from "../../../../consts";

class CallScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCallScreen: false,
      showIncomingScreen: false,
      showOutgoingScreen: false,
      showIframeScreen: false,
      userCancelled:false,
      actionGenerated: (action, payload) => {
        return null;
      }
    }
    this.url = COMETCHAT_CONSTANTS.SOUND_URL;
    this.audio = new Audio(this.url);
    this.audio.addEventListener('ended', function () {
      this.currentTime = 0;
      this.play();
    }, false);

    this.togglePlay = this.togglePlay.bind(this);
    this.callScreenElement = React.createRef();
  }

  togglePlay = () => {
    this.setState({
      play: !this.state.play
  });

  this.state.play ? this.audio.play() : this.audio.pause();
  }
  componentDidMount() {
    console.log("I am called");
    this.cometChatManager = new CometChatManager();

    let listenerID = "UNIQUE_LISTENER_ID_CALL_SCREEN";

    CometChat.addCallListener(
      listenerID,
      new CometChat.CallListener({
        onIncomingCallReceived: (call) => {
          if (!this.state.callIProgress) {
            this.audio.play()
            this.setState({ showCallScreen: true, showIncomingScreen: true, callIProgress: call });
          } else {
            //TODO reject the call
          }

        },
        onOutgoingCallAccepted: (call) => {
          console.log('outgoing call accepted','callscreen')
          console.log(call);
            this.setState({
              showCallScreen: true,
              showIncomingScreen: false,
              showOutgoingScreen: false,
              showIframeScreen: true,
              callIProgress: call
            },()=>{
              console.log(this.state.showOutgoingScreen)
            });
         
          CometChat.startCall(
            call.getSessionId(),
            document.getElementById("cp-call-screen-container"),
            new CometChat.OngoingCallListener({
              onUserJoined: user => {
                this.audio.pause()
                /* Notification received here if another user joins the call. */
                console.log("User joined call:", user);
                /* this method can be use to display message or perform any actions if someone joining the call */
              },
              onUserLeft: user => {
                this.audio.pause()
                this.props.actionGenerated("userLeft", {call:call});
                /* Notification received here if another user left the call. */
                console.log("User left call:", user);
                /* this method can be use to display message or perform any actions if someone leaving the call */
              },
              onCallEnded: call => {
                console.log('call screen call ended')
                this.audio.pause()
                /* Notification received here if current ongoing call is ended. */
                this.setState({
                  showCallScreen: false,
                  showIncomingScreen: false,
                  showOutgoingScreen: false,
                  showIframeScreen: false,
                  callIProgress: undefined
                });
                this.props.actionGenerated("callEnded", {call:call});
                /* hiding/closing the call screen can be done here. */
              }
            })
          );

        },
        onOutgoingCallRejected: (call) => {
          console.log("here we ate ", call)
          this.audio.pause()
          this.setState({
            showCallScreen: false,
            showIncomingScreen: false,
            showOutgoingScreen: false,
            showIframeScreen: false,
            callIProgress: undefined
          });
          this.state.actionGenerated("callEnded", {});
        },
        onIncomingCallCancelled: (call) => {
          this.audio.pause()
          this.setState({
            showCallScreen: false,
            showIncomingScreen: false,
            showOutgoingScreen: false,
            showIframeScreen: false,
            callIProgress: undefined
          });
          
          this.state.actionGenerated("callEnded", {});
        }
      })
    );
  }

  componentDidUpdate(){
    console.log('did update called')
  }
  componentWillUpdate(nextProps,nextState){
    console.log('nextProps',nextProps)
    console.log('nextprops',this.state)
    //if user clicked on video or audio call we turn usercancelled false
    if(nextProps.userClicked === 'yes' && this.state.userCancelled===true){
      console.log('user clicked condition')
      if(this.audio.paused){
        this.audio.play()
      }
      
      this.setState({
        userCancelled:false,
        showOutgoingScreen:true,
        showCallScreen:true,
        callIProgress: nextProps.outgoingCall
      })
    }
    if(nextProps.outgoingCall!==undefined && this.state.showOutgoingScreen ===false && this.state.userCancelled===false){
      console.log('outgoing call condition')
      this.audio.play()
      this.setState({
        showCallScreen: true,
        // showIncomingScreen: false,
        showOutgoingScreen: true,
        // showIframeScreen: false,
        callIProgress: nextProps.outgoingCall
      });
    }else if(nextProps.outgoingCall===undefined && nextProps.showOutgoingCall===false && this.state.showOutgoingScreen ===true && this.state.showIframeScreen!==true){
      this.audio.pause()
      this.setState({
        showCallScreen: false,
        showOutgoingScreen: false,
        // showIncomingScreen: false,
        // showIframeScreen: false,
        // callIProgress: nextProps.outgoingCall
      });
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    // console.log(props, "asfajfhjkaskfaksf aksf kajsf kas ");
    // if (props.outgoingCall!==undefined) {
    //   // console.log('setting outgoing call to',props.outgoingCall)
    //   this.setState({
    //     showCallScreen: true,
    //     showIncomingScreen: false,
    //     showOutgoingScreen: true,
    //     showIframeScreen: false,
    //     callIProgress: props.outgoingCall
    //   });
    // }
    
  }
  // static getDerivedStateFromProps(props, state) {    
  //   if (props.outgoingCall) {
  //     return {
  //       showCallScreen: false,
  //       showIncomingScreen: false,
  //       showOutgoingScreen: true,
  //       showIframeScreen: false,
  //     }
  //   }
  //   return null;
  //   // return props;

  // }
  render() {
    return (this.state.showCallScreen ? <div className="cp-call-screen-container">
      {this.state.showIframeScreen ? <div id="cp-call-screen-container" className="cp-call-screen-container" style={{ zIndex: 100000 }}>

      </div> : null}
      {(this.state.showIncomingScreen ? <div className="cp-incoming-call-screen" >
        <div className="m-a" >
          Calling...
        </div>

        <div className="m-a cp-call-title" >
          {this.state.callIProgress.sender.name}
        </div>
        <div className="m-a" >
          <Avatar src={this.state.callIProgress.sender}></Avatar>
        </div>

        <div className="m-a" style={{ display: "flex", width: '25%' }}>
          <div className="m-a p-xl cp-accept-button" onClick={() => {
            this.audio.pause();
            CometChat.acceptCall(this.state.callIProgress.sessionId).then(call => {
              this.setState({
                showCallScreen: true,
                showIncomingScreen: false,
                showOutgoingScreen: false,
                showIframeScreen: true,
              });
              CometChat.startCall(
                call.getSessionId(),
                document.getElementById("cp-call-screen-container"),
                new CometChat.OngoingCallListener({
                  onUserJoined: user => {
                    /* Notification received here if another user joins the call. */
                    console.log("User joined call:", user);
                    /* this method can be use to display message or perform any actions if someone joining the call */
                  },
                  onUserLeft: user => {
                    /* Notification received here if another user left the call. */
                    CometChat.endCall(call.getSessionId()).then(call=>{
                      console.log('call ended',call)
                      this.setState({
                        showCallScreen: false,
                        showIncomingScreen: false,
                        showOutgoingScreen: false,
                        showIframeScreen: false,
                        callIProgress: undefined
                      });
                      this.props.actionGenerated("callEnded", {call:call});
                    },err=>{
                      console.log('error occured',err)
                    })
                    this.props.actionGenerated("userLeft", {call:call});
                    console.log("User left call:", user);
                    /* this method can be use to display message or perform any actions if someone leaving the call */
                  },
                  onCallEnded: call => {
                    console.log('call ended',call)
                    /* Notification received here if current ongoing call is ended. */
                    this.setState({
                      showCallScreen: false,
                      showIncomingScreen: false,
                      showOutgoingScreen: false,
                      showIframeScreen: false,
                      callIProgress: undefined
                    });
                    this.props.actionGenerated("callEnded", {call:call});
                    /* hiding/closing the call screen can be done here. */
                  }
                })
              );
              console.log("callStarted");
            });
          }}>
            ACCEPT
          </div>
          <div className="m-a p-xl cp-reject-button" onClick={() => {
            CometChat.rejectCall(this.state.callIProgress.sessionId, CometChat.CALL_STATUS.REJECTED).then((res) => {
              console.log('success',res)
              this.setState({
                showCallScreen: false,
                showIncomingScreen: false,
                showOutgoingScreen: false,
                showIframeScreen: false,
                callIProgress: undefined

              })
              this.audio.pause();
              console.log(this.props)
              this.state.actionGenerated("callEnded", {});
            }, err => {
              console.log('error ending call',err)
              this.setState({
                showCallScreen: false,
                showIncomingScreen: false,
                showOutgoingScreen: false,
                showIframeScreen: false,
                callIProgress: undefined
              });
              this.audio.pause();
              this.state.actionGenerated("callEnded", {});
            }
            );
          }
          }>
            REJECT
           </div>
        </div>

      </div> : null)}
      {(this.state.showOutgoingScreen ? <div className="cp-outgoing-call-screen" >
        <div className="m-a" >
          Calling...
        </div>

        <div className="m-a cp-call-title" >
          {this.state.callIProgress.receiver.name}
        </div>
        <div className="m-a" >
          <Avatar src={this.state.callIProgress.receiver}></Avatar>
        </div>

        <div className="m-a" style={{ display: "flex", width: '25%' }}>
          <div className="m-a p-xl cp-reject-button" onClick={() => {
            CometChat.rejectCall(this.state.callIProgress.sessionId, CometChat.CALL_STATUS.CANCELLED).then((call) => {

              this.setState({
                showCallScreen: false,
                showIncomingScreen: false,
                showOutgoingScreen: false,
                showIframeScreen: false,
                callIProgress: undefined,
                userCancelled:true
              });
              this.audio.pause();
              this.props.actionGenerated("callCancelled", {call:call});
            }, err => {
              this.audio.pause();
              this.setState({
                showCallScreen: false,
                showIncomingScreen: false,
                showOutgoingScreen: false,
                showIframeScreen: false,
                callIProgress: undefined,
                userCancelled:true
              });
              this.state.actionGenerated("callEnded", {});
            });

          }
          }>
            CANCEL
           </div>
        </div>

      </div> : null)}
    </div > : null)
  }
}

export default CallScreen;